import "theme/global.css"
import "./BlogPostTemplate.css"
// import "prismjs/themes/prism-tomorrow.css"

import React from "react"

import { graphql } from "gatsby"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

// import { rhythm, scale } from "theme/typography"

import {
  Header,
  LayoutContainer,
  Footer,
  Divider,
  SEO,
  BrowseLinksSection,
  MDXComponents,
} from "components"

import GlobalStyle from "theme/GlobalStyle"

const BlogGrid = styled.div`
  display: grid;
`

const Main = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`
const SideBar = styled.div`
  grid-column-start: 3;
  grid-column-end: 3;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    display: none;
  }
  /* background: red; */
`

const SideBarContainer = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.space[6]}
  max-width: 300px;
  width: 100%;
  padding: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 0.8em;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  a {
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
    opacity: 0.6;
  }
  a:hover {
    text-decoration: underline;
  }
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const { image } = this.props.pageContext
    console.log(this.props)
    return (
      <>
        <GlobalStyle />
        <Header />
        <main>
          <SEO
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
            image={image}
          />
          <BlogGrid>
            <Main>
              <LayoutContainer hasPaddingTop={false} mb={5} mt={5}>
                <h1>{post.frontmatter.title}</h1>
                <p style={{ opacity: 0.75 }}>{post.frontmatter.subtitle}</p>
                <p
                  style={{
                    // ...scale(-1 / 5),
                    display: `block`,
                    // marginBottom: rhythm(1),
                    // marginTop: rhythm(-1),
                    opacity: 0.5,
                  }}
                >
                  {post.frontmatter.date}
                </p>
              </LayoutContainer>
              <Divider mb={4} />

              <LayoutContainer
                hasPaddingTop={false}
                maxWidth={"680px"}
                mb={5}
                mt={5}
              >
                <MDXProvider components={MDXComponents}>
                  <MDXRenderer>{post.body}</MDXRenderer>
                </MDXProvider>
              </LayoutContainer>
            </Main>

            {/*}{typeof post.tableOfContents.items !== "undefined" && (
            <SideBar>
              <SideBarContainer>
                {post.tableOfContents.items.map((item, index) => {
                  return (
                    <div key={"tb" + index}>
                      <Link to={this.props.uri + item.url}>{item.title}</Link>
                    </div>
                  )
                })}
              </SideBarContainer>
            </SideBar>
          )}*/}
          </BlogGrid>
        </main>
        <BrowseLinksSection previous={previous} next={next} />

        <Footer />
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      tableOfContents
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
